import * as React from "react"
import { Link } from "gatsby"

import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import SpeakWithAdvisor from "../components/ui/SpeakWithAdvisor";
import calendarsecondaryicn from "../assets/img/calendar-secondary-icn.svg";
import seminarsecondaryicn from "../assets/img/seminar-secondary-icn.svg";

const MeetWithUs = ({ location }) => (
	<Layout location={location} heading1="Meeting Options" heading2="Let us guide you through Medicare.">
    	<Seo title="Meet With Us" meta={[{description: 'Our UVM Health Advantage Plan Guides will help you choose the plan that\'s right for you'}]} bodyclass="meet-with-us subpage" />
		
		<div className="constrained">
        	<div className="content-constrained">
          		<div className="flexwrap_subpage">
					<main>
						<h3>Our UVM Health Advantage Plan Guides will help you choose the plan that&rsquo;s right for you.</h3>
						
						<p>Our UVM Health Advantage Plan Guides will listen to your needs and help you understand your options so that you can make a well-informed decision with no disruption to your care.</p>
						<p>Our goal is simple &ndash; no surprises and complete confidence! We want you to know exactly what you&rsquo;ll get with a UVM Health Advantage plan. And after you&rsquo;ve selected a plan, our Plan Guides will work to ensure a smooth transition.</p>

						<div className="ctas secondary">
							<div className="flexwrap">
								<div className="secondary-cta">
									<img src={calendarsecondaryicn} alt="Schedule a personal consultation with an UVMHA Medicare Plan Guide" width="60" height="60" className="icon" />
									<p className="head"><b>Schedule a personal consultation with an a UVM Health Advantage Plan Guide.</b></p>
									<p>Search available phone, video, or in-person appointments, or request a meeting at a time and place that&rsquo;s convenient for you.</p>
									<a href="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling@mvphealthcare.onmicrosoft.com/bookings/" className="button external_button" target="_blank" rel="noreferrer">Search Appointments</a>
									<Link to="/meet-with-us/request-consultation/" className="button secondary">Request a Consultation</Link>
								</div>
								<div className="secondary-cta">
									<img src={seminarsecondaryicn} alt="Attend a Free Medicare Seminar" width="60" height="60" className="icon" />
									<p className="head"><b>Attend a Free Medicare Seminar.</b></p>
									<p>Learn the basics of Medicare or explore the plans available to you in one of our live online seminars.</p>
									<Link to="/meet-with-us/attend-a-seminar/" className="button secondary">Find a Seminar</Link>
								</div>
							</div>
						</div>
						
						<h4>Meet with a Plan Guide at a location near you!</h4>
						<p>Talk one-on-one with a UVM Health Advantage Plan Guide at a UVM Health Network facility near you. Walk-ins only, no appointment needed.</p>
						<div className="meetingwrap">
							<ul>
								<li>
									<p><strong>Alice Hyde Medical Center</strong><br />
									133 Park St.<br />
									Malone, NY 12953<br />
									Rep: Torey Perry<br />
									Thursdays 9 a.m.-1 p.m.</p>
								</li>
								<li>
									<p><strong>CVPH</strong><br />
									75 Beekman St.<br />
									Plattsburgh, NY 12901<br />
									Rep: Torey Perry<br />
									Wednesdays 9 a.m.-1 p.m.</p>
								</li>
								<li>
									<p><strong>Elizabethtown Community Hospital</strong><br />
									75 Park Street<br />
									Elizabethtown, NY 12932<br />
									Rep: Torey Perry<br />
									Tuesdays 9 a.m.-1 p.m.</p>
								</li>
								<li>
									<p><strong>Central Vermont Medical Center</strong><br />
									130 Fisher Rd.<br />
									Berlin, VT 05602<br />
									Rep: Kathryn Rueda<br />
									Tuesdays 9 a.m.-1 p.m.</p>
								</li>
								<li>	
									<p><strong>UVM Medical Center</strong><br />
									1 South Prospect St.<br />
									Burlington, VT 05401<br />
									Rep: Zach Heywood<br />
									Wednesdays 9 a.m.-3 p.m.</p>
								</li>
								<li>
									<p><strong>UVM Medical Center</strong><br />
									111 Colchester Rd.<br />
									Burlington, VT 05401<br />
									Rep: Joerg Bernhard<br />
									Mondays 9 a.m.-3 p.m.</p>
								</li>
							</ul>
						</div>

						{/*<p className="disclaimer">Per current safety guidelines, you must be fully vaccinated against COVID-19 and received a booster dose if eligible to attend in-person appointments at an MVP Health Care office. If you are not vaccinated, please select a video or phone appointment. Masks are not required in MVP offices, but you may choose to wear a mask if you prefer.</p> */}

						<SpeakWithAdvisor />
					</main>
         		</div>
        	</div>
      	</div>
	</Layout>
)

export default MeetWithUs
